var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"uk-container uk-margin-medium-top"},[_c('div',{staticClass:"uk-grid uk-child-width-1-2@m"},[_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('router-link',{staticClass:"uk-align-right uk-margin-remove-bottom",attrs:{"to":{ name: 'trader.discussions.index' }}},[_vm._v("Show All")]),_c('h3',{staticClass:"uk-margin-remove"},[_vm._v("Active Discussions")]),_c('hr'),_c('table',{staticClass:"uk-table uk-table-divider"},[_c('tbody',_vm._l((_vm.topics),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"12%"}},[_c('router-link',{attrs:{"to":{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }}},[_c('img',{class:("avatar " + (_vm.loading ? 'skeleton' : '')),attrs:{"src":topic.author.avatar,"alt":"image"}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }}},[(! topic.opened)?_c('h5',{class:("uk-margin-remove " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" ")]):_vm._e(),(topic.opened)?_c('p',{staticClass:"uk-margin-remove",staticStyle:{"color":"#808080"}},[_vm._v(" "+_vm._s(topic.title)+" ")]):_vm._e(),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : 'uk-margin-remove-top uk-margin-remove-bottom'},[_c('small',[_vm._v(_vm._s(_vm.$localizer.parse(topic.date))+" - "+_vm._s(topic.diff))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }}},[_c('p',{staticClass:"uk-margin-remove text-black"},[_vm._v("Replies")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : 'uk-margin-remove uk-margin-remove-bottom'},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),0)])],1)]),_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-bottom"},[_c('h3',{staticClass:"uk-margin-remove"},[_vm._v("Want to earn money with us?")]),_c('p',{staticClass:"uk-margin-remove"},[_vm._v("Share this link to receive credits for every new user that registeres with it")]),_c('p',{staticClass:"uk-margin-small-top uk-text-danger"},[_vm._v(_vm._s(_vm.user.referral_code))]),_c('p',[_vm._v(" Share: "),_c('social-share',{attrs:{"url":_vm.user.referral_code}})],1)]),_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-bottom"},[_c('h3',{staticClass:"uk-margin-remov"},[_vm._v("Join our premium telegram channel")]),_c('a',{staticClass:"uk-button uk-button-primary",attrs:{"href":_vm.telegram_link,"target":"_blank"}},[_vm._v("Join Now")])]),_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('router-link',{staticClass:"uk-align-right uk-margin-remove",attrs:{"to":{ name: 'trader.conference.index' }}},[_vm._v("Show All")]),_c('h3',{staticClass:"uk-margin-remove"},[_vm._v("Today's Live Conference Videos")]),_c('hr'),_vm._m(0),_c('table',{staticClass:"uk-table uk-table-divider"},[_c('tbody',_vm._l((_vm.conference),function(video,i){return _c('tr',{key:("conference-" + i)},[_c('td',[_c('router-link',{attrs:{"to":{
                      name: 'trader.conference.show',
                      params: { id: video.id },
                    }}},[_c('h5',{class:("uk-margin-remove" + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(video.title)+" ")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : 'uk-margin-remove'},[_c('small',[_vm._v(_vm._s(_vm.$localizer.parse(video.date)))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                      name: 'trader.conference.show',
                      params: { id: video.id },
                    }}},[_c('p',{staticClass:"uk-margin-remove text-black"},[_vm._v("Comments")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : 'uk-margin-remove'},[_vm._v(" "+_vm._s(video.comments)+" ")])])],1)])}),0)])],1)])]),_c('div',{staticClass:"uk-grid uk-child-width-expand"},[_c('div',{staticClass:"uk-width-2-3@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('h3',[_vm._v("Correlation Analysis")]),_c('correlation')],1)]),_c('div',{staticClass:"uk-width-1-3@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('h3',[_vm._v("Continue Learning")]),_c('p',[_vm._v("There are more videos in the academy.")]),_c('router-link',{staticClass:"uk-button uk-button-primary",attrs:{"to":{ name: 'trader.academy.index' }}},[_vm._v("Visit Academy")])],1),_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-margin-medium-top uk-card-small"},[_c('h3',[_vm._v("Interact With Other Members")]),_c('p',[_vm._v("See who is online, chat and share ideas.")]),_c('router-link',{staticClass:"uk-button uk-button-primary",attrs:{"to":{ name: 'trader.users.index' }}},[_vm._v("See Users")])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-alert uk-alert-primary"},[_c('p',{staticClass:"uk-margin-remove"},[_vm._v("Live conference meetings are held twice a day.")]),_c('p',{staticClass:"uk-margin-remove"},[_vm._v("London session at 7-8 am London time (GMT+0)")]),_c('p',{staticClass:"uk-margin-remove"},[_vm._v("New York session at 7-8 am New York time (GMT-5)")])])}]

export { render, staticRenderFns }