<template>
  <div>
    <div class="uk-container uk-margin-medium-top">
      <div class="uk-grid uk-child-width-1-2@m">
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-card-small">
            <router-link :to="{ name: 'trader.discussions.index' }" class="uk-align-right uk-margin-remove-bottom">Show All</router-link>
            <h3 class="uk-margin-remove">Active Discussions</h3>
            <hr>
            <table class="uk-table uk-table-divider">
              <tbody>
                <tr v-for="(topic, i) in topics" :key="`discussion-${i}`">
                  <td width="12%">
                    <router-link
                      :to="{
                        name: 'trader.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <img
                        :src="topic.author.avatar"
                        alt="image"
                        :class="`avatar ${loading ? 'skeleton' : ''}`"
                      />
                    </router-link>
                  </td>
                  <td class="border-right">
                    <router-link
                      :to="{
                        name: 'trader.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <h5
                        v-if="! topic.opened"
                        :class="`uk-margin-remove ${
                          loading ? 'skeleton skeleton-text' : ''
                        }`"
                      >
                        {{ topic.title }}
                      </h5>
                      <p
                        v-if="topic.opened"
                        class="uk-margin-remove"
                        style="color: #808080"
                      >
                        {{ topic.title }}
                      </p>
                      <p :class="loading ? 'skeleton skeleton-text' : 'uk-margin-remove-top uk-margin-remove-bottom'">
                        <small>{{ $localizer.parse(topic.date) }} - {{ topic.diff }}</small>
                      </p>
                    </router-link>
                  </td>
                  <td width="10%">
                    <router-link
                      :to="{
                        name: 'trader.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <p class="uk-margin-remove text-black">Replies</p>
                      <p :class="loading ? 'skeleton skeleton-text' : 'uk-margin-remove uk-margin-remove-bottom'">
                        {{ topic.comments }}
                      </p>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-bottom">
            <h3 class="uk-margin-remove">Want to earn money with us?</h3>
            <p class="uk-margin-remove">Share this link to receive credits for every new user that registeres with it</p>
            <p class="uk-margin-small-top uk-text-danger">{{ user.referral_code }}</p>
            <p>
              Share: 
              <social-share :url="user.referral_code" />
            </p>
          </div>
          <div class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-bottom">
            <h3 class="uk-margin-remov">Join our premium telegram channel</h3>
            <a :href="telegram_link" target="_blank" class="uk-button uk-button-primary">Join Now</a>
          </div>
          <div class="uk-card uk-card-default uk-card-body uk-card-small">
            <router-link :to="{ name: 'trader.conference.index' }" class="uk-align-right uk-margin-remove">Show All</router-link>
            <h3 class="uk-margin-remove">Today's Live Conference Videos</h3>
            <hr>
            <div class="uk-alert uk-alert-primary">
              <p class="uk-margin-remove">Live conference meetings are held twice a day.</p>
              <p class="uk-margin-remove">London session at 7-8 am London time (GMT+0)</p>
              <p class="uk-margin-remove">New York session at 7-8 am New York time (GMT-5)</p>
            </div>
            <table class="uk-table uk-table-divider">
              <tbody>
                <tr v-for="(video, i) in conference" :key="`conference-${i}`">
                  <td>
                    <router-link
                      :to="{
                        name: 'trader.conference.show',
                        params: { id: video.id },
                      }"
                    >
                      <h5
                        :class="`uk-margin-remove${
                          loading ? 'skeleton skeleton-text' : ''
                        }`"
                      >
                        {{ video.title }}
                      </h5>
                      <p :class="loading ? 'skeleton skeleton-text' : 'uk-margin-remove'">
                        <small>{{ $localizer.parse(video.date) }}</small>
                      </p>
                    </router-link>
                  </td>
                  <td width="10%">
                    <router-link
                      :to="{
                        name: 'trader.conference.show',
                        params: { id: video.id },
                      }"
                    >
                      <p class="uk-margin-remove text-black">Comments</p>
                      <p :class="loading ? 'skeleton skeleton-text' : 'uk-margin-remove'">
                        {{ video.comments }}
                      </p>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="uk-grid uk-child-width-expand">
        <div class="uk-width-2-3@m">
          <div class="uk-card uk-card-default uk-card-body uk-card-small">
            <h3>Correlation Analysis</h3>
            <correlation />
          </div>
        </div>
        <div class="uk-width-1-3@m">
          <div class="uk-card uk-card-default uk-card-body uk-card-small">
            <h3>Continue Learning</h3>
            <p>There are more videos in the academy.</p>
            <router-link :to="{ name: 'trader.academy.index' }" class="uk-button uk-button-primary">Visit Academy</router-link>
          </div>
          <div class="uk-card uk-card-default uk-card-body uk-margin-medium-top uk-card-small">
            <h3>Interact With Other Members</h3>
            <p>See who is online, chat and share ideas.</p>
            <router-link :to="{ name: 'trader.users.index' }" class="uk-button uk-button-primary">See Users</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Correlation from '@/components/charts/Correlation'

export default {
  components: {
    Correlation
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      topics: [
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
      ],
      conference: [
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
      ],
      loading: true,
      telegram_link: ''
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios
        .get("/api/v1/trader/home")
        .then((response) => {
          this.topics = response.data.topics;
          this.conference = response.data.conference;
          this.loading = false;
          this.telegram_link = response.data.telegram_link
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
};
</script>
